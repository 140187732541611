import React, { useEffect } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormCheck,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilLockLocked, cilUser } from "@coreui/icons";
import { FormProvider, useForm } from "react-hook-form";
import { RegisterRequest } from "src/types/api/RegisterRequest";
import { FormGroup } from "@mui/material";
import {
  useMeQuery,
  useRegisterMutation,
  useResetPasswordMutation,
  useResetPasswordTokenMutation,
} from "src/services/AuthService";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ResetPasswordRequest } from "src/types/api/ResetPasswordRequest";
import Swal from "sweetalert2";
import { ApiErrorSetter } from "src/helpers/ApiErrorSetter";
import { ResetPasswordTokenRequest } from "src/types/api/ResetPasswordTokenRequest";
import useDisableButton from "src/hooks/disableButtonHook";

const PasswordReset = () => {
  const navigate = useNavigate();
  const methods = useForm<ResetPasswordTokenRequest>();
  const { currentData: meResponse } = useMeQuery();
  const me = meResponse?.data;
  const {
    formState: { errors },
    reset,
  } = methods;
  const [resetPasswordToken] = useResetPasswordTokenMutation();

  const { token } = useParams();
  const disabledParams = useDisableButton();

  useEffect(() => {
    reset({
      token: token,
    });
  }, []);

  const handleOnSubmit = async (form: ResetPasswordTokenRequest) => {
    try {
      methods.clearErrors();
      await resetPasswordToken(form).unwrap();
    } catch (e) {
      ApiErrorSetter(e, methods.setError);
    }
  };

  useEffect(() => {
    if (me) {
      navigate("/");
    }
  }, [me]);

  return (
    <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={9} lg={7} xl={6}>
            <CCard className="mx-4">
              <CCardBody className="p-4">
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(handleOnSubmit)}>
                    <h1>Resetowanie hasła</h1>
                    <p className="text-body-secondary">
                      Odzyskaj dostęp do konta sitely.pl
                    </p>
                    <CInputGroup className="mb-3">
                      <CFormInput
                        placeholder="Hasło"
                        type="password"
                        invalid={!!errors.password}
                        feedbackInvalid={errors.password?.message}
                        {...methods.register("password")}
                      />
                    </CInputGroup>

                    <CInputGroup className="mb-3">
                      <CFormInput
                        placeholder="Powtórz hasło"
                        type="password"
                        invalid={!!errors.password_confirmation}
                        feedbackInvalid={errors.password_confirmation?.message}
                        {...methods.register("password_confirmation")}
                      />
                    </CInputGroup>

                    <div className="d-grid">
                      <button
                        className="btn btn-success"
                        type="submit"
                        {...disabledParams}
                      >
                        Ustaw nowe hasło
                      </button>
                    </div>
                  </form>
                </FormProvider>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default PasswordReset;

import {
  CFormInput,
  CFormSelect,
  CInputGroup,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { FormGroup } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import { subscribe } from "diagnostics_channel";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  useGetEmailQuery,
  useUpdateEmailMutation,
} from "src/services/EmailService";
import { useGetOrderQuery } from "src/services/OrderService";
import { Email } from "src/types/Email";
import { OrderItem } from "src/types/OrderItem";
import { Website } from "src/types/Website";
import Form from "./Form";
import { ApiErrorSetter } from "src/helpers/ApiErrorSetter";
import useDisableButton from "src/hooks/disableButtonHook";

const Update = () => {
  const { id } = useParams();
  const { currentData: emailResponse } = useGetEmailQuery(id ?? skipToken);
  const email = emailResponse?.data;
  const methods = useForm<Email>();
  const { reset, setError, watch, clearErrors, register } = methods;
  const [updateEmail] = useUpdateEmailMutation();

  const navigate = useNavigate();
  const form = watch();

  const handleOnClose = () => {
    navigate("/emails");
  };

  const handleOnSubmit = async () => {
    try {
      clearErrors();
      const res = await updateEmail(form).unwrap();
      handleOnClose();
    } catch (e) {
      ApiErrorSetter(e, setError);
    }
  };

  const disabledParams = useDisableButton();

  useEffect(() => {
    reset(email);
  }, [email]);

  return (
    <CModal visible={true} size="xl" onClose={handleOnClose}>
      <CModalHeader>
        <h5>Dodaj adres email</h5>
      </CModalHeader>
      <CModalBody>
        <FormProvider {...methods}>
          <Form onSubmit={handleOnSubmit} editMode={true} />
        </FormProvider>
      </CModalBody>
      <CModalFooter className="mt-5">
        <button
          onClick={handleOnSubmit}
          className="btn btn-primary"
          {...disabledParams}
        >
          Zapisz
        </button>
        <button onClick={handleOnClose} className="btn btn-danger text-white">
          Anuluj
        </button>
      </CModalFooter>
    </CModal>
  );
};

export default Update;

import { createSlice } from "@reduxjs/toolkit";
import { AuthService } from "src/services/AuthService";

export const TokenReducer = createSlice({
  name: "token",
  initialState: null as string | null,
  reducers: {
    // set: (state, action) => action.payload,
    logout: () => null,
  },
  extraReducers(builder) {
    builder.addMatcher(
      AuthService.endpoints.login.matchFulfilled,
      (state, action) => action.payload.access_token
    );

    builder.addMatcher(
      AuthService.endpoints.register.matchFulfilled,
      (state, action) => action.payload.access_token
    );

    builder.addMatcher(
      AuthService.endpoints.logout.matchFulfilled,
      (state, action) => null
    );

    builder.addMatcher(
      AuthService.endpoints.resetPasswordToken.matchFulfilled,
      (state, action) => action.payload.access_token
    );
  },
});

export const { logout } = TokenReducer.actions;

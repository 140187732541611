import { ListParams } from "src/types/api/ListParams";
import { ListResponse, SingleItemResponse } from "../types/api/Response";
import { Website } from "../types/Website";
import Api from "./Api";

export const WebsiteService = Api.injectEndpoints({
  endpoints: (build) => ({
    websites: build.query<ListResponse<Website>, ListParams>({
      query: (params) => ({
        url: `websites`,
        params: params,
      }),
      providesTags: (result: any, error: any, arg: any) => [
        { type: "WEBSITES", params: arg },
        "WEBSITES",
      ],
    }),
    getWebsite: build.query<SingleItemResponse<Website>, string>({
      query: (websiteId) => ({
        url: `websites/${websiteId}`,
      }),
      providesTags: (result: any, error: any, arg: any) => [
        { type: "WEBSITES", params: arg },
        "WEBSITES",
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useWebsitesQuery,
  useLazyWebsitesQuery,
  useGetWebsiteQuery,
  useLazyGetWebsiteQuery,
} = WebsiteService;

import {
  CButtonGroup,
  CCol,
  CImage,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { skipToken } from "@reduxjs/toolkit/query";
import { subscribe } from "diagnostics_channel";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import DataTable from "src/components/DataTable/DataTable";
import {
  useBackupsQuery,
  useCreateBackupMutation,
  useGetBackupQuery,
  useRestoreBackupMutation,
} from "src/services/BackupService";
import { useGetOrderQuery } from "src/services/OrderService";
import {
  useGetWebsiteQuery,
  useWebsitesQuery,
} from "src/services/WebsiteService";
import { OrderItem } from "src/types/OrderItem";
import { Website } from "src/types/Website";
import { DefaultListParams, ListParams } from "src/types/api/ListParams";
import Swal from "sweetalert2";

const Backups = () => {
  const [params, setParams] = useState<ListParams>({
    ...DefaultListParams,
    sort: "created_at",
    direction: "desc",
  });

  const { id } = useParams();
  const { currentData: websiteResponse } = useGetWebsiteQuery(id ?? skipToken);
  const website = websiteResponse?.data;

  const { currentData: backupsResponse, isFetching } = useBackupsQuery(
    id ? { params: params, websiteId: id } : skipToken
  );
  const rows = backupsResponse?.data;
  const meta = backupsResponse?.meta;

  const [restoreBackupMutation] = useRestoreBackupMutation();
  const [createBackupMutation] = useCreateBackupMutation();

  const navigate = useNavigate();
  const handleOnClose = () => {
    navigate("/websites");
  };

  const createBackup = (websiteId: string) => {
    Swal.fire({
      title: "Czy jesteś pewien?",

      confirmButtonText: "Tak",
      cancelButtonText: "Nie",
      showCancelButton: true,
      showConfirmButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        createBackupMutation(websiteId)
          .unwrap()
          .then(() => {
            Swal.fire({
              title: "Backup zostanie niebawem utworzony",
              icon: "success",
            });
          });
      }
    });
  };

  const restoreBackup = (backupId: string, createdAt: string) => {
    Swal.fire({
      title: "Czy jesteś pewien?",
      text: `Ta operacja spowuje nadpisanie aktualnej wersji strony i przywróci jej do stanu z ${createdAt}`,
      confirmButtonText: "Tak",
      cancelButtonText: "Nie",
      showCancelButton: true,
      showConfirmButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        restoreBackupMutation(backupId)
          .unwrap()
          .then(() => {
            Swal.fire({
              title: "Backup zostanie niebawem przywrócony",
              icon: "success",
            });
          })
          .catch(() => {
            Swal.fire({
              title: "Nie można przywrócić tej kopii zapasowej",
              icon: "error",
            });
          });
        handleOnClose();
      }
    });
  };

  const columns = [
    {
      field: "created_at",
      headerName: "Data utworzenia",
      flex: 1,
    },

    {
      field: "actions",
      headerName: "Akcje",
      width: 143,
      renderCell: ({ row: { id, created_at } }: any) => {
        return (
          <CButtonGroup role="group" size="sm">
            <button
              className="btn btn-primary"
              color="primary"
              onClick={() => restoreBackup(id, created_at)}
            >
              Przywróć backup
            </button>
          </CButtonGroup>
        );
      },
    },
  ];

  const onParamsChange = (params: any) => {
    setParams({ ...params });
  };
  return (
    <CModal visible={true} size="xl" onClose={handleOnClose}>
      {rows && meta && (
        <>
          <CModalHeader>
            <h5>Kopie zapasowe strony {website?.domain?.name}</h5>
          </CModalHeader>
          <CModalBody>
            {website && (
              <CRow>
                <CCol className="text-end mb-3">
                  <button
                    className="btn btn-primary"
                    onClick={() => createBackup(website.id)}
                  >
                    Utwórz kopię zapasową
                  </button>
                </CCol>
              </CRow>
            )}

            <DataTable
              onParamsChange={onParamsChange}
              params={{ ...params, loading: isFetching }}
              rows={rows}
              meta={meta}
              columns={columns}
            />
          </CModalBody>
          <CModalFooter>
            <button onClick={handleOnClose} className="btn btn-primary">
              Zamknij
            </button>
          </CModalFooter>
        </>
      )}
    </CModal>
  );
};

export default Backups;

import {
  CButton,
  CButtonGroup,
  CCol,
  CContainer,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CRow,
} from "@coreui/react";
import moment from "moment";
import { useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import DataTable from "src/components/DataTable/DataTable";
import constants from "src/constants";
import { useOrdersQuery } from "src/services/OrderService";
import { DefaultMeta } from "src/types/api/ListMeta";
import { DefaultListParams, ListParams } from "src/types/api/ListParams";

const Index = () => {
  const [params, setParams] = useState<ListParams>(DefaultListParams);

  const { currentData: servicesResponse, isFetching } = useOrdersQuery(params);
  const rows = servicesResponse?.data ?? [];
  const meta = servicesResponse?.meta ?? DefaultMeta;

  const getStatusText = (status: number) => {
    switch (status) {
      case constants.ORDER_STATUS_CART:
        return constants.ORDER_STATUS_CART_TEXT;
      case constants.ORDER_STATUS_WAITING_FOR_PAYMENT:
        return constants.ORDER_STATUS_WAITING_FOR_PAYMENT_TEXT;
      case constants.ORDER_STATUS_PAYMENT_VERIFICATION:
        return constants.ORDER_STATUS_PAYMENT_VERIFICATION_TEXT;
      case constants.ORDER_STATUS_FINISHED:
        return constants.ORDER_STATUS_FINISHED_TEXT;
      case constants.ORDER_STATUS_CANCELED:
        return constants.ORDER_STATUS_CANCELED_TEXT;
        detault: return <></>;
    }
    return <></>;
  };

  const getBadgeClass = (status: number) => {
    switch (status) {
      case constants.ORDER_STATUS_WAITING_FOR_PAYMENT:
        return "bg-secondary";
      case constants.ORDER_STATUS_PAYMENT_VERIFICATION:
        return "bg-warning";
      case constants.ORDER_STATUS_FINISHED:
        return "bg-success";
      default:
        return "bg-primary";
    }
  };

  const columns = [
    { field: "number", headerName: "Numer", width: 70 },
    {
      field: "confirmed_at",
      headerName: "Data",
      width: 150,
      valueGetter: ({ value }: any) => moment(value).format("YYYY-MM-DD HH:mm"),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: ({ value }: any) => (
        <span className={"badge " + getBadgeClass(value)}>
          {getStatusText(value)}
        </span>
      ),
    },
    { field: "price", headerName: "W sumie", flex: 1 },
    {
      field: "price_with_discount",
      headerName: "W sumie (z rabatem)",
      flex: 1,
    },
    {
      field: "procentage_discount",
      headerName: "Rabat procentowy",
      flex: 1,
    },
    {
      field: "amount_discount",
      headerName: "Rabat kwotowy",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Akcje",
      width: 145,
      renderCell: ({ row: { id, status } }: any) => {
        return (
          <CButtonGroup role="group" aria-label="Basic example" size="sm">
            <Link
              to={`/orders/${id}`}
              className="btn btn-primary"
              color="primary"
            >
              Podgląd
            </Link>
            <Link
              to={`/cart/pay/${id}`}
              className={
                "btn btn-primary" +
                (status != constants.ORDER_STATUS_WAITING_FOR_PAYMENT
                  ? " disabled"
                  : "")
              }
              color="primary"
            >
              Opłać
            </Link>
          </CButtonGroup>
        );
      },
    },
  ];

  const onParamsChange = (params: any) => {
    setParams({ ...params });
  };

  return (
    <CRow>
      <CCol>
        <DataTable
          onParamsChange={onParamsChange}
          params={{ ...params, loading: isFetching }}
          rows={rows}
          meta={meta}
          columns={columns}
        />
        <Outlet />
      </CCol>
    </CRow>
  );
};

export default Index;

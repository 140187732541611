import React, { useEffect } from 'react'
import { AppContent, AppSidebar, AppFooter, AppHeader } from '../components/index'
import { Navigate, Outlet, createSearchParams, useLocation, useNavigate } from 'react-router-dom'
import { useMeQuery } from 'src/services/AuthService'
import { useSelector } from 'react-redux'
import { CCard, CCardBody, CContainer } from '@coreui/react'
import Notifications from 'src/components/Notifications'
import Pusher from "pusher-js";
window.pusher = Pusher;


const DefaultLayout = () => {
  const { isError, isFetching, data: meResponse } = useMeQuery();
  const me = meResponse?.data;
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = createSearchParams({
    to: location.pathname
  }).toString()

  if (!me && !isFetching) {
    navigate({
      pathname: '/login',
      search: searchParams
    })
  }

  return (
    me ? <div>

      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100">
        <AppHeader />
        <div className="body flex-grow-1">
          <CContainer>
            <CCard>
              <CCardBody>
                <Outlet />
              </CCardBody>
            </CCard>
          </CContainer>

        </div>
        <AppFooter />
      </div>
    </div> : <></>
  )
}

export default DefaultLayout

import React from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormCheck,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilLockLocked, cilUser } from "@coreui/icons";
import { FormProvider, useForm } from "react-hook-form";
import { RegisterRequest } from "src/types/api/RegisterRequest";
import { FormGroup } from "@mui/material";
import {
  useMeQuery,
  useRegisterMutation,
  useResetPasswordMutation,
} from "src/services/AuthService";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ResetPasswordRequest } from "src/types/api/ResetPasswordRequest";
import Swal from "sweetalert2";
import { ApiErrorSetter } from "src/helpers/ApiErrorSetter";
import useDisableButton from "src/hooks/disableButtonHook";

const Password = () => {
  const methods = useForm<ResetPasswordRequest>();
  const {
    formState: { errors },
  } = methods;
  const [resetPassword] = useResetPasswordMutation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const disabledParams = useDisableButton();

  const handleOnSubmit = async (form: ResetPasswordRequest) => {
    try {
      methods.clearErrors();
      await resetPassword(form).unwrap();
      Swal.fire({
        icon: "success",
        title: "Link do zresetowania hasła został wystłany na twój adres email",
      });
      navigate("/");
    } catch (e) {
      ApiErrorSetter(e, methods.setError);
    }
  };

  return (
    <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={9} lg={7} xl={6}>
            <CCard className="mx-4">
              <CCardBody className="p-4">
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(handleOnSubmit)}>
                    <h1>Resetowanie hasła</h1>
                    <p className="text-body-secondary">
                      Odzyskaj dostęp do konta sitely.pl
                    </p>
                    <CInputGroup className="mb-3">
                      <CFormInput
                        placeholder="Email"
                        type="email"
                        invalid={!!errors.email}
                        feedbackInvalid={errors.email?.message}
                        {...methods.register("email")}
                      />
                    </CInputGroup>

                    <div className="d-grid">
                      <button
                        className="btn btn-success"
                        type="submit"
                        {...disabledParams}
                      >
                        Wyślij link do resetu hasła
                      </button>
                    </div>
                  </form>
                </FormProvider>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Password;

import { CFormInput, CInputGroup } from "@coreui/react";
import { useForm } from "react-hook-form";
import {
  useAddDomainToCartMutation,
  useCheckDomainAvailabilityMutation,
  useCheckDomainMutation,
} from "src/services/CartService";
import { useDebounce } from "@uidotdev/usehooks";
import { useEffect, useState } from "react";
import { Order } from "src/types/Order";
import { error } from "console";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { Subjectable } from "src/types/Subjectable";
import useDisableButton from "src/hooks/disableButtonHook";

interface CheckDomainAvailabilityResponse {
  register: number;
  renew: number;
  message: string;
  transfer: number;
  available: boolean;
}

interface RegisterDomainProps {
  cart: Order;
  onSubmit?: any;
  subjectable?: Subjectable;
}
interface CheckDomain {
  name: string;
}
const RegisterDomain = ({
  cart,
  onSubmit,
  subjectable,
}: RegisterDomainProps) => {
  const [checkDomainAvailability] = useCheckDomainAvailabilityMutation();
  const [addDomainToCart] = useAddDomainToCartMutation();

  const methods = useForm<CheckDomain>();
  const {
    register,
    watch,
    clearErrors,
    formState: { errors },
  } = methods;
  const [valid, setValid] = useState<CheckDomainAvailabilityResponse | null>();
  const value = watch("name");

  const disableProps = useDisableButton();
  disableProps.disabled = disableProps.disabled || !valid;

  const debouncedValue = useDebounce(value, 1000);

  const handleCheckDomain = async (domain: string) => {
    try {
      const res = await checkDomainAvailability({
        name: domain,
      }).unwrap();
      setValid(res);
    } catch (e) {
      const error = e as FetchBaseQueryError;
      setValid(null);

      Object.entries<Record<any, any>>((error.data as any).errors).forEach(
        ([item, errors]) => {
          return methods.setError(item as any, {
            message: errors.join(","),
          });
        }
      );
    }
  };

  useEffect(() => {
    if (debouncedValue) {
      handleCheckDomain(debouncedValue);
    }
  }, [debouncedValue]);

  useEffect(() => {
    setValid(null);
    clearErrors();
  }, [value]);

  const handleOnSubmit = async (form: CheckDomain) => {
    try {
      const res = await addDomainToCart({
        cartId: cart.id,
        domainAction: {
          name: debouncedValue,
          action: 1,
          ...subjectable,
        },
      }).unwrap();
      if (onSubmit) {
        onSubmit();
      }
    } catch (e) {}
  };

  return (
    <form onSubmit={methods.handleSubmit(handleOnSubmit)}>
      <CInputGroup className="mb-3">
        <CFormInput
          {...register("name")}
          valid={valid?.available}
          invalid={!!errors.name}
          feedbackValid={valid?.message}
          feedbackInvalid={errors.name?.message}
        />
      </CInputGroup>
      <button type="submit" {...disableProps}>
        Dodaj do zamówienia
      </button>
    </form>
  );
};

export default RegisterDomain;

import { ListParams } from "src/types/api/ListParams";
import { ListResponse, SingleItemResponse } from "../types/api/Response";
import Api from "./Api";
import { Service } from "src/types/Service";
import { Template } from "src/types/Template";

export const ServiceService = Api.injectEndpoints({
  endpoints: (build) => ({
    services: build.query<ListResponse<Service>, ListParams>({
      query: (params) => ({
        url: `/services`,
        params: params,
      }),
      providesTags: (result: any, error: any, arg: any) => [
        { type: "SERVICES", params: arg },
        "SERVICES",
      ],
    }),
    getService: build.query<SingleItemResponse<Template>, string>({
      query: (templateId) => ({
        url: `services/${templateId}`,
      }),
      providesTags: (result: any, error: any, arg: any) => [
        { type: "SERVICES", params: arg },
        "SERVICES",
      ],
    }),
    updateService: build.mutation<SingleItemResponse<Service>, Service>({
      query: (service: Service) => ({
        url: `/services/${service.id}`,
        body: service,
        method: "PATCH",
      }),

      invalidatesTags: ["SERVICES"],
    }),

    deleteService: build.mutation<null, string>({
      query: (service: string) => ({
        url: `/services/${service}`,

        method: "DELETE",
      }),

      invalidatesTags: ["SERVICES"],
    }),
    createService: build.mutation<SingleItemResponse<Service>, Service>({
      query: (service: Service) => ({
        url: `/services`,
        body: service,
        method: "POST",
      }),

      invalidatesTags: ["SERVICES"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useServicesQuery,
  useLazyServicesQuery,
  useGetServiceQuery,
  useLazyGetServiceQuery,
  useCreateServiceMutation,
  useDeleteServiceMutation,
  useUpdateServiceMutation,
} = ServiceService;

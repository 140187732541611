import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMeQuery, useOneTimeTokenMutation } from "src/services/AuthService";

const OAuth = () => {
  const [createOneTimeToken] = useOneTimeTokenMutation();

  const { currentData: meResponse, isFetching } = useMeQuery();
  const me = meResponse?.data;

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const redirect = searchParams.get("redirect");
  const {
    origin: redirectOrigin,
    searchParams: redirectSearchParams,
    pathname: redirectPathname,
  } = new URL(redirect as string);

  const urlParams = new URL(document.location.href);

  if (!me && !isFetching) {
    const params = new URLSearchParams({
      to: urlParams.pathname + urlParams.search,
    });

    console.log(params.toString());

    navigate({
      pathname: "/login",
      search: params.toString(), //searchParams.toString(),
    });
  }

  useEffect(() => {
    if (me) {
      createOneTimeToken()
        .unwrap()
        .then((res) => {
          redirectSearchParams.append("token", res.one_time_token);
          const url =
            redirectOrigin +
            redirectPathname +
            "?" +
            redirectSearchParams.toString();
          console.log(url);
          document.location.href = url;
        })
        .catch((e) => {
          redirectSearchParams.append(
            "error",
            JSON.stringify((e as FetchBaseQueryError).data)
          );
          document.location.href =
            redirectOrigin +
            redirectPathname +
            "?" +
            redirectSearchParams.toString();
        });
    }
  }, [me]);
  return <></>;
};
export default OAuth;

//http://localhost:3000/oauth?redirect=https://sitely.pl

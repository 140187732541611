import { LoggedUser } from "src/types/LoggedUser";
import { SingleItemResponse } from "../types/api/Response";
import { User } from "../types/User";
import Api from "./Api";
import { LoginRequest } from "../types/api/LoginRequest";
import { logout } from "src/store/reducers/token";
import { RegisterRequest } from "src/types/api/RegisterRequest";
import { ResetPasswordRequest } from "src/types/api/ResetPasswordRequest";
import { ResetPasswordTokenRequest } from "src/types/api/ResetPasswordTokenRequest";
import { OneTimeToken } from "src/types/api/OneTimeToken";

export const AuthService = Api.injectEndpoints({
  endpoints: (build) => ({
    me: build.query<SingleItemResponse<User>, void>({
      query: () => `/auth/me`,
      providesTags: ["ME"],
    }),

    updateMe: build.mutation<SingleItemResponse<User>, User>({
      query: (me: User) => ({
        url: `/auth/me`,
        body: me,
        method: "PATCH",
      }),
      invalidatesTags: ["ME"],
    }),

    login: build.mutation<LoggedUser, LoginRequest>({
      query: (LoginRequest: LoginRequest) => ({
        url: `/auth/login`,
        body: LoginRequest,
        method: "POST",
      }),

      invalidatesTags: ["ME"],
    }),

    ovhLogin: build.mutation<any, string>({
      query: (redirect) => ({
        url: `/ovh/login`,
        method: "POST",
        body: { redirect: redirect },
      }),
    }),

    resetPassword: build.mutation<any, ResetPasswordRequest>({
      query: (body) => ({
        url: `/auth/password/reset`,
        method: "POST",
        body: body,
      }),
    }),

    resetPasswordToken: build.mutation<any, ResetPasswordTokenRequest>({
      query: (body) => ({
        url: `/auth/password/reset/${body.token}`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["ME"],
    }),

    oneTimeToken: build.mutation<OneTimeToken, void>({
      query: () => ({
        url: `/auth/one-time/login`,
        method: "POST",
      }),
    }),

    register: build.mutation<LoggedUser, RegisterRequest>({
      query: (registerRequest: RegisterRequest) => ({
        url: `/auth/register`,
        body: registerRequest,
        method: "POST",
      }),

      invalidatesTags: (res) => (res ? ["ME"] : []),
    }),
    logout: build.mutation<LoggedUser, null>({
      query: () => ({
        url: `/auth/logout`,
        method: "POST",
      }),

      invalidatesTags: ["ME"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useMeQuery,
  useLazyMeQuery,
  useLogoutMutation,
  useLoginMutation,
  useRegisterMutation,
  useOvhLoginMutation,
  useUpdateMeMutation,
  useResetPasswordMutation,
  useResetPasswordTokenMutation,
  useOneTimeTokenMutation,
} = AuthService;

import { Domain } from "src/types/Domain";
import { ListResponse, SingleItemResponse } from "../types/api/Response";
import Api from "./Api";
import { ListParams } from "src/types/api/ListParams";
import { DomainPrice } from "src/types/DomainPrice";

export const DomainPriceService = Api.injectEndpoints({
  endpoints: (build) => ({
    domainPrices: build.query<ListResponse<DomainPrice>, ListParams>({
      query: (params) => ({
        url: `/domain-prices`,
        params: params,
      }),
      providesTags: (result: any, error: any, arg: any) => [
        { type: "DOMAIN_PRICES", params: arg },
        "DOMAIN_PRICES",
      ],
    }),
    getDomainPrice: build.query<SingleItemResponse<DomainPrice>, string>({
      query: (domainPriceId) => ({
        url: `domain-prices/${domainPriceId}`,
      }),
      providesTags: (result: any, error: any, arg: any) => [
        { type: "DOMAIN_PRICES", params: arg },
        "DOMAIN_PRICES",
      ],
    }),

    updateDomainPrice: build.mutation<
      SingleItemResponse<DomainPrice>,
      DomainPrice
    >({
      query: (domainPrice: DomainPrice) => ({
        url: `/domain-prices/${domainPrice.id}`,
        body: domainPrice,
        method: "PATCH",
      }),

      invalidatesTags: ["DOMAIN_PRICES"],
    }),

    deleteDomainPrice: build.mutation<null, string>({
      query: (domainPrice: string) => ({
        url: `/domain-prices/${domainPrice}`,

        method: "DELETE",
      }),

      invalidatesTags: ["DOMAIN_PRICES"],
    }),
    createDomainPrice: build.mutation<
      SingleItemResponse<DomainPrice>,
      DomainPrice
    >({
      query: (domainPrice: DomainPrice) => ({
        url: `/domain-prices`,
        body: domainPrice,
        method: "POST",
      }),

      invalidatesTags: ["DOMAIN_PRICES"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useDomainPricesQuery,
  useLazyDomainPricesQuery,
  useGetDomainPriceQuery,
  useLazyGetDomainPriceQuery,
  useCreateDomainPriceMutation,
  useDeleteDomainPriceMutation,
  useUpdateDomainPriceMutation,
} = DomainPriceService;

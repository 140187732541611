import { useRef, useState } from "react";

const useDisableButton = (time: number = 3000) => {
  const ref = useRef<HTMLButtonElement>(null);
  const [disabled, setDisabled] = useState<boolean>(false);

  ref.current?.addEventListener("click", (e) => {
    setTimeout(() => {
      setDisabled(true);
    }, 0);
    setTimeout(() => {
      setDisabled(false);
    }, time);
  });

  return {
    ref: ref,
    disabled: disabled,
  };
};

export default useDisableButton;

import { CToast, CToastBody, CToastHeader, CToaster } from "@coreui/react";
import { useEffect, useRef, useState } from "react";
import Echo from "laravel-echo";
import { useMeQuery } from "src/services/AuthService";
import { useSelector } from "react-redux";
import CIcon from "@coreui/icons-react";
import { iconsMap } from "src/helpers/iconsMap";

const Toast = ({ notification }: any) => {
  const icon = iconsMap[notification.icon];
  return (
    <CToast animation={true} visible={true}>
      <CToastHeader closeButton>
        <CIcon icon={icon} customClassName="nav-icon" width={20} height={20} />

        <div className="fw-bold me-auto"> {notification.title}</div>
        <small>{notification.created_at}</small>
      </CToastHeader>
      <CToastBody>{notification.content}</CToastBody>
    </CToast>
  );
};
const Notifications = () => {
  const token = useSelector<any>((store) => store.token) as string;
  const { data: meResponse } = useMeQuery();
  const me = meResponse?.data;

  const [toast, addToast] = useState<any>();
  const toaster = useRef(null);

  useEffect(() => {
    if (me) {
      const echo = new Echo({
        broadcaster: "reverb",
        key: process.env.REACT_APP_REVERB_APP_KEY,
        wsHost: process.env.REACT_APP_REVERB_SERVER_HOST,
        wsPort: process.env.REACT_APP_REVERB_SERVER_PORT,
        wssPort: process.env.REACT_APP_REVERB_SERVER_PORT,
        forceTLS: process.env.REACT_APP_REVERB_TLS,
        enabledTransports: ["ws", "wss"],
        debug: true,
        authEndpoint: `${process.env.REACT_APP_API_URL}/broadcasting/auth`,
        auth: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      echo
        .private(`App.Models.User.${me.id}`)
        .notification((notification: any) => {
          const toast = <Toast notification={notification} />;
          addToast(toast);
        });
    }
  }, [me]);

  return (
    <>
      <CToaster
        className="p-3"
        placement="top-end"
        push={toast}
        ref={toaster}
      />
    </>
  );
};

export default Notifications;

import { cilSettings } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CButtonGroup,
  CCol,
  CContainer,
  CProgress,
  CProgressBar,
  CRow,
} from "@coreui/react";

import { useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import DataTable from "src/components/DataTable/DataTable";
import { useWebsitesQuery } from "src/services/WebsiteService";
import { Domain } from "src/types/Domain";
import { DefaultMeta } from "src/types/api/ListMeta";
import { DefaultListParams, ListParams } from "src/types/api/ListParams";
import moment from "moment";

const Index = () => {
  const [params, setParams] = useState<ListParams>(DefaultListParams);

  const { currentData: servicesResponse, isFetching } =
    useWebsitesQuery(params);
  const rows = servicesResponse?.data ?? [];
  const meta = servicesResponse?.meta ?? DefaultMeta;

  const navigate = useNavigate();

  const columns = [
    {
      field: "domain",
      headerName: "Domena",
      flex: 1,
      valueGetter: ({ value }: any) => value?.name ?? "-",
    },

    {
      field: "email_usage",
      headerName: "Zajętość maili",
      width: 150,
      renderCell: ({ value, row }: any) => {
        const quota = row.website_params.mail_quota;
        const usage = row.mail_usage;
        const procent = (usage / quota) * 100;

        return (
          <CCol>
            <CProgress value={procent}>
              <CProgressBar className="overflow-visible  px-2">
                {usage} / {quota}
              </CProgressBar>
            </CProgress>
          </CCol>
        );
      },
    },
    {
      field: "storage_usage",
      headerName: "Zajętość dysku",
      width: 150,
      renderCell: ({ value, row }: any) => {
        const quota = row.website_params.storage_quota;
        const usage = row.storage_usage;
        const procent = (usage / quota) * 100;

        return (
          <CCol>
            <CProgress value={procent}>
              <CProgressBar className="overflow-visible  px-2">
                {usage} / {quota}
              </CProgressBar>
            </CProgress>
          </CCol>
        );
      },
    },
    {
      field: "plan",
      headerName: "Pakiet",
      flex: 1,
      valueGetter: ({ value }: any) => value?.name ?? "-",
    },

    {
      field: "expire_at",
      headerName: "Wygasa",
      width: 150,
      valueGetter: ({ value }: any) => moment(value).format("YYYY-MM-DD HH:mm"),
    },
    {
      field: "actions",
      headerName: "Akcje",
      width: 450,
      renderCell: ({ row: { id, status, domain } }: any) => {
        return (
          <CButtonGroup role="group" size="sm">
            <Link
              to={`/websites/${id}/backups`}
              className="btn btn-primary"
              color="primary"
            >
              Kopie zapasowe
            </Link>
            <Link
              to={`/websites/${id}`}
              className="btn btn-primary"
              color="primary"
            >
              Szczegóły
            </Link>
            <Link
              to={`/websites/${id}`}
              className="btn btn-primary"
              color="primary"
            >
              Zmień pakiet
            </Link>
            <a
              className={"btn btn-primary" + (domain ? "" : " disabled")}
              href={"https://" + domain?.name + "/wp-admin"}
            >
              Zarządzaj witryną
            </a>
          </CButtonGroup>
        );
      },
    },
  ];

  const onParamsChange = (params: any) => {
    setParams({ ...params });
  };

  return (
    <>
      <CRow>
        <CCol className="text-end mb-3">
          <a
            href="https://sitely.pl/szablony"
            className="btn btn-primary"
            color="primary"
          >
            Zamów nową stronę
          </a>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <DataTable
            onParamsChange={onParamsChange}
            params={{ ...params, loading: isFetching }}
            rows={rows}
            meta={meta}
            columns={columns}
          />
          <Outlet />
        </CCol>
      </CRow>
    </>
  );
};

export default Index;

import { Plan } from "src/types/Plan";
import { ListResponse, SingleItemResponse } from "../types/api/Response";
import Api from "./Api";
import { Service } from "src/types/Service";
import { ListParams } from "src/types/api/ListParams";
import { Backup } from "src/types/Backup";

export const BackupService = Api.injectEndpoints({
  endpoints: (build) => ({
    backups: build.query<
      ListResponse<Backup>,
      { params: ListParams; websiteId: string }
    >({
      query: ({ websiteId, params }) => ({
        url: `/websites/${websiteId}/backups`,
        params: params,
      }),
      providesTags: (result: any, error: any, arg: any) => [
        { type: "BACKUPS", params: arg },
        "BACKUPS",
      ],
    }),

    getBackup: build.query<SingleItemResponse<Backup>, string>({
      query: (planId) => ({
        url: `backups/${planId}`,
      }),
      providesTags: (result: any, error: any, arg: any) => [
        { type: "BACKUPS", params: arg },
        "BACKUPS",
      ],
    }),

    restoreBackup: build.mutation<SingleItemResponse<Backup>, string>({
      query: (backupId) => ({
        url: `/backups/${backupId}/restore`,
        method: "POST",
      }),

      invalidatesTags: ["BACKUPS"],
    }),

    createBackup: build.mutation<SingleItemResponse<Backup>, string>({
      query: (websiteId) => ({
        url: `/websites/${websiteId}/backups`,
        method: "POST",
      }),

      invalidatesTags: ["BACKUPS"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useBackupsQuery,
  useGetBackupQuery,
  useLazyBackupsQuery,
  useLazyGetBackupQuery,
  useCreateBackupMutation,
  useRestoreBackupMutation,
} = BackupService;

import { Plan } from "src/types/Plan";
import { ListResponse, SingleItemResponse } from "../types/api/Response";
import Api from "./Api";
import { Service } from "src/types/Service";
import { ListParams } from "src/types/api/ListParams";

export const PlanService = Api.injectEndpoints({
  endpoints: (build) => ({
    plans: build.query<ListResponse<Plan>, ListParams>({
      query: (params) => ({
        url: `/plans`,
        params: params,
      }),
      providesTags: (result: any, error: any, arg: any) => [
        { type: "PLANS", params: arg },
        "PLANS",
      ],
    }),

    getPlan: build.query<SingleItemResponse<Plan>, string>({
      query: (planId) => ({
        url: `plans/${planId}`,
      }),
      providesTags: (result: any, error: any, arg: any) => [
        { type: "PLANS", params: arg },
        "PLANS",
      ],
    }),

    updatePlan: build.mutation<SingleItemResponse<Plan>, Plan>({
      query: (plan: Plan) => ({
        url: `/plans/${plan.id}`,
        body: plan,
        method: "PATCH",
      }),

      invalidatesTags: ["PLANS"],
    }),

    deletePlan: build.mutation<null, string>({
      query: (plan: string) => ({
        url: `/plans/${plan}`,

        method: "DELETE",
      }),

      invalidatesTags: ["PLANS"],
    }),
    createPlan: build.mutation<SingleItemResponse<Plan>, Plan>({
      query: (plan: Plan) => ({
        url: `/plans`,
        body: plan,
        method: "POST",
      }),

      invalidatesTags: ["PLANS"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyPlansQuery,
  usePlansQuery,
  useGetPlanQuery,
  useLazyGetPlanQuery,
  useUpdatePlanMutation,
  useDeletePlanMutation,
  useCreatePlanMutation,
} = PlanService;
